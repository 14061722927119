<template>
  <div class="elv-transactions-gain-loss-table-list-content">
    <CustomTable
      ref="tableListRef"
      v-loading="tableLoading"
      border
      stripe
      :tableData="tableData.list"
      :tableHeader="tableHeader"
      :paginationData="pageData"
      :tableCountNumber="tableData?.filteredCount"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
      @row-click="onViewDetail"
    >
      <template #type="{ data }">
        <IconWithTitleCell
          :title-weight="400"
          :svg-name="typeData(data)?.icon"
          :title="typeData(data)?.label ? t(typeData(data)?.label) || '-' : '-'"
        />
      </template>
      <template #datetime="{ data }">
        <DateTimeCell :params="{ data }" bottom-fields="gainLossHash" />
      </template>
      <template #baseAsset="{ data }">
        <IconWithTitleCell
          :title-weight="400"
          :icon="gainOrLossAssetInfo(data).platformIcon"
          :title="gainOrLossAssetInfo(data).name"
        />
      </template>
      <template #positionSide="{ data }">
        <IconWithTitleCell
          :title-weight="400"
          :svg-name="positionSideInfo(data).svgName || ''"
          :title="t(positionSideInfo(data).label) || '-'"
        />
      </template>
      <template #currency="{ data }">
        <IconWithTitleCell
          :title-weight="400"
          :icon="gainOrLossAssetInfo(data).icon"
          :title="gainOrLossAssetInfo(data).title"
        />
      </template>
      <template #amount="{ data }">
        <VerticalTextCell
          :up-text="upAmount(data).text"
          :down-text="downAmountFC(data).text"
          :up-desc="upAmount(data).desc"
          :down-desc="downAmountFC(data).desc"
          :up-weight="500"
        />
      </template>
      <template #category="{ data }">
        <div v-if="!isEmpty(data.journalType)" class="elv-transactions-trade-tablelist-content-category">
          <SvgIcon name="report-checkmark" width="14" height="14" fill="#50970B" />{{ data.journalType.name }}
        </div>
        <div v-else class="elv-transactions-trade-tablelist-content-category-empty">-</div>
      </template>
      <template #detailTag="{ data }">
        <div class="elv-transactions-table-row__operating">
          <Popover
            trigger="hover"
            placement="bottomLeft"
            overlay-class-name="elv-transactions-table-row__operating-popover"
            :offset="2"
          >
            <SvgIcon width="14" height="14" name="more-dian" class="elv-transactions-table-row__operating-icon" />

            <template #content>
              <div
                class="elv-transactions-table-row__operating-item"
                :style="{ display: 'flex', 'align-items': 'center' }"
                @click="onViewDetail(data)"
              >
                <SvgIcon name="side-panel--close" width="18" height="18" fill="#D0D4D9" />{{ t('button.viewDetail') }}
              </div>
            </template>
          </Popover>
        </div>
      </template>
    </CustomTable>
  </div>
  <DetailOverlay
    ref="detailOverlayRef"
    :is-batch="false"
    :currentData="overlayDrawerData.currentData"
    @onResetList="onResetList"
  />
</template>
<script lang="ts" setup>
import { $t } from '@/i18n/index'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es' // find
import { Popover } from 'ant-design-vue'
import { ElMessage } from 'element-plus'
import DetailOverlay from './DetailOverlay.vue'
import { gainLossType, positionOptions } from '@/config'
import { useEntityStore } from '@/stores/modules/entity'
import CustomTable from '../../../components/CustomTable.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'

defineProps({
  tableData: {
    type: Object,
    default: () => ({})
  },
  pageData: {
    type: Object,
    require: true,
    default: () => {}
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['onResetList', 'onChangePage', 'onChangePageSize'])

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const tableHeader: any[] = [
  {
    dataIndex: 'type',
    title: $t('common.type'),
    slotName: 'type',
    width: 200
  },
  {
    dataIndex: 'datetime',
    title: $t('common.dateTime'),
    slotName: 'datetime',
    width: 200
  },
  {
    dataIndex: 'baseAsset',
    title: $t('report.Base Asset'),
    slotName: 'baseAsset',
    width: 200
  },
  {
    dataIndex: 'positionSide',
    title: $t('report.positionAndSide'),
    slotName: 'positionSide',
    width: 200
  },
  {
    dataIndex: 'currency',
    title: $t('report.gainLossAsset'),
    slotName: 'currency',
    width: 200
  },
  {
    dataIndex: 'amount',
    title: $t('report.gainLossAmount'),
    slotName: 'amount',
    width: 200,
    headerAlign: 'right'
  },
  {
    dataIndex: 'category',
    title: $t('title.transactionCategory'),
    slotName: 'category',
    fixed: 'right',
    min_width: 200
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 44
  }
]

const tableListRef = ref()
const detailOverlayRef = ref()
const currentData: any = ref({})
const overlayDrawerData: any = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false,
  journalModel: 'add'
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const typeData = computed(() => {
  return (row: any) => {
    const data: any = gainLossType.find((item: any) => item.value === row?.type) || {}
    return data
  }
})

const positionSideInfo = computed(() => {
  return (data: any) => {
    return positionOptions.find((item) => item.value === data?.positionSide) || { label: '', svgName: '' }
  }
})

const gainOrLossAssetInfo = computed(() => {
  return (data: any) => {
    const assetData = { name: '', icon: '', title: '-', platformIcon: '' }
    if (isEmpty(data.asset?.underlyingCurrency) && isEmpty(data.asset?.derivative)) {
      return assetData
    }
    if (isEmpty(data.asset?.underlyingCurrency)) {
      assetData.name = data.asset?.derivative?.name || '-'
      assetData.title = data.asset?.derivative?.marginAssetCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.derivative?.marginAssetCurrency?.logo || ''
      assetData.platformIcon = data.entityAccount?.platform?.logo || ''
    } else {
      assetData.name = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.title = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.underlyingCurrency?.logo || ''
      assetData.platformIcon = data.asset?.underlyingCurrency?.logo || ''
    }
    return assetData
  }
})

const upAmount = computed(() => {
  return (data: any) => {
    if (!data?.amount) return { text: '-', desc: '' }
    const value = formatNumberToSignificantDigits(Number(data?.amount), 2, '', false)
    const symbol = data.asset?.underlyingCurrency?.showSymbol
      ? data.asset?.underlyingCurrency?.showSymbol
      : data.asset?.derivative?.marginAssetCurrency?.showSymbol || ''
    return {
      text: `${value} \n${symbol || ''}`,
      desc: `${formatNumber(data?.amount, 20) || ''} ${symbol}`
    }
  }
})

const downAmountFC = computed(() => {
  return (data: any) => {
    if (!data?.amountFC) return { text: '-', desc: '' }
    const fcValue = formatNumberToSignificantDigits(
      data?.amountFC,
      2,
      `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
    )
    const descText = `${
      entityDetail?.value.underlyingCurrency?.sign || entityDetail?.value.underlyingCurrency?.showSymbol
    }${formatNumber(data?.amountFC, 20) || ''} `
    return {
      text: fcValue,
      desc: descText
    }
  }
})

/**
 * 查看每条数据详细信息
 * @param data  点击行数据
 */
const onViewDetail = (data: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = data
  overlayDrawerData.currentData = data
  detailOverlayRef.value?.onCheckDrawerStatus()
}

/**
 * 分页器页码发生变化时触发
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * 分页器每页条数发生变化时触发
 * @param pageSize 每页条数
 */
const onChangePageSize = (pageSize: number) => {
  emit('onChangePageSize', pageSize)
}

/**
 * 更新列表数据
 */
const onResetList = () => {
  emit('onResetList')
}
</script>
<style lang="scss">
.elv-transactions-gain-loss-table-list-content {
  .elv-base-cell-vertical-text-up,
  .elv-base-cell-vertical-text-down {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .elv-transactions-gain-loss-table-list-content-category {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: fit-content;
    padding: 0px 8px;
    background-color: #f1f8ea;
    border-radius: 14px;
    color: #41780c;
    font-size: 11px;

    svg {
      margin-right: 4px;
    }
  }

  .elv-transactions-gain-loss-table-list-content-category-empty {
    color: #2f63eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .elv-transactions-trade-tablelist-content-category {
    display: flex;
    height: 22px;
    width: fit-content;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 14px;
    background: #f1f8ea;
    color: #41780c;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-weight: 400;
  }

  .elv-transactions-trade-tablelist-content-category-empty {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-base-cell-icon-title {
    padding: 12px 0px;
  }
}
</style>
<style lang="scss" scoped>
.elv-transactions-gain-loss-table-list-content {
  height: 100%;
  position: relative;

  .elv-component-custom-table-panel {
    :deep(.elv-component-custom-table) {
      .el-table__inner-wrapper {
        .el-table__body-wrapper {
          tr > td {
            &:last-child {
              width: 44px;

              .cell {
                min-width: 44px;
                width: 44px !important;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
